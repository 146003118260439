const translation = {
	AUTHORIZATION_REQUIRED: 'Необходимо авторизоваться',
	LOGIN_FAILED: 'Неверный логин или пароль',
	name: 'Название',
	description: 'Описание',
	create: 'Создать',
	Auth: {
		title: 'Авторизация',
		login: 'Войти',
		register: 'Зарегистрироваться',
		logout: 'Выйти',
		forgotPassword: 'Забыли пароль?',
		recover: 'Восстановить',
		required: 'Необходимо заполнить поле',
	},
	Email: {
		title: 'E-mail',
		hint: 'mail@example.com',
		field: 'Электронная почта',
	},
	Password: {
		title: 'Пароль',
		hint: 'Пароль...',
		input: 'Введите пароль',
		new: 'Введите новый пароль',
		repeat: 'Повторите пароль',
		newHint: 'Оставьте поля пустыми, чтобы не менять пароль',
		empty: 'Введите пароль в оба поля',
		noMatch: 'Пароли не совпадают!',
		match: 'Пароли совпадают',
	},
	Person: {
		firstName: 'Имя',
		lastName: 'Фамилия',
		middleName: 'Отчество',
		fullName: 'Ф.И.О.',
	},
	User: {
		title: 'Пользователь',
		plural: 'Пользователи',
		create: 'Новый пользователь',
		email: 'E-mail',
		emailVerified: 'E-mail подтвержден',
		name: 'Логин',
		firstName: 'Имя',
		lastName: 'Фамилия',
		middleName: 'Отчество',
		leftComment: 'оставил(а) комментарий',
		createdTask: 'создал задачу',
		telegram: 'Telegram ID',
		username: 'Логин',
		password: 'Пароль',
		avatar: 'Аватар',
		fullName: 'ФИО',
		error: {
			email: {
				custom: {
					email: 'Неверный e-mail',
				},
				uniqueness: 'Пользователь с этим e-mail уже существует',
				verifySent: 'На e-mail отправлено письмо для подтвержения',
			},
		},
	},
	Role: {
		title: 'Роль',
		plural: 'Роли',
		create: 'Новая роль',
		created: 'Созданo',
		modified: 'Изменено',
		name: 'Код',
		description: 'Название',
		isSaving: 'Права сохраняются...',
	},
	ACL: {
		title: 'Право',
		plural: 'Права',
		create: 'Новое право',
		principalType: 'Вид субъекта',
		app: 'Приложение',
		role: 'Роль',
		user: 'Пользователь',
		accessType: 'Действие',
		read: 'Чтение',
		write: 'Запись',
		execute: 'Выполнение',
		all: 'Все',
		principalId: 'Субъект',
		model: {
			title: 'Модель',
			user: 'Пользователи',
			project: 'Проекты',
			person: 'Персоны',
		},
		permission: 'Право',
		allow: 'Разрешить',
		deny: 'Запретить',
		property: 'Свойство',
		allowAll: 'Разрешить все',
	},
	RoleMapping: {
		title: 'Назначение роли',
		plural: 'Назначенные роли',
		create: 'Новое назначение роли',
	},
	Comments: {
		title: 'Комментарий',
		plural: 'Комментарии',
		placeholder: 'Оставьте комментарий',
		submit: 'Отправить',
	},
	Label: {
		title: 'Метка',
		plural: 'Метки',
		create: 'Новая метка',
	},
	File: {
		title: 'Файл',
		plural: 'Файлы',
		uploadFile: 'Загрузить файл',
		uploadImage: 'Загрузить изображение',
		uploadStart: 'начинаю загрузку',
		uploading: 'загрузка файла...',
		uploadComplete: 'загрузка завершена',
		notSelected: 'файл не выбран',
		willBeUploaded: 'Будет загружен',
		wrongType: 'Неверный тип файла',
	},
	video: {
		error: {
			invalidURL: 'URL введен неверно',
			invalidSource: 'Видео с данной платформы не поддерживается',
		},
	},
	agreeText:
		'Я даю согласие Государственному Собранию (Ил Тумэн) Республики Саха (Якутия) на автоматизированную, а также без использования средств автоматизации обработку персональных данных, переданных мною в связи с обращением, а именно на совершение действий, предусмотренных пунктом 3 части 1 статьи 3 Федерального закона от 27 июля 2006 года № 152-ФЗ «О персональных данных», включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), блокирование, удаление, уничтожение. Согласие может быть отозвано мною в письменной форме.',
};

export default { translation };
