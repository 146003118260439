import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import parse from 'date-fns/parse';
import format from 'date-fns/format';

import { Popup, Loader, Select, MaskedInput } from '@smartplatform/ui';
import store from '../../store';
import validateEmail from '../../utils/validateEmail';
import getCurrentDeputies from '../../utils/getCurrentDeputies';
import { ReCaptcha } from '../../components';
import './style.scss';
import t from '../../../i18n/index';

const dateFormat = 'dd.MM.yyyy';

@observer
export default class Appointments extends React.Component {
	@observable register = false;
	@observable password = '';
	@observable password2 = '';
	@observable passwordError = '';
	@observable password2Error = '';

	@observable subject = '';
	@observable lastName = '';
	@observable firstName = '';
	@observable middleName = '';
	@observable email = '';
	@observable phone = '';
	@observable address = '';
	@observable deputy = null;
	@observable date = null;
	@observable dates = [];
	@observable deputies = [];

	@observable subjectError = '';
	@observable lastNameError = '';
	@observable firstNameError = '';
	@observable middleNameError = '';
	@observable emailError = '';
	@observable phoneError = '';
	@observable addressError = '';
	@observable deputyError = null;
	@observable connectError = '';
	@observable agreeError = '';
	@observable dateError = '';

	@observable agreement = false;
	@observable isLoading = false;
	@observable initialized = false;
	@observable appointmentId = null;
	@observable file = null;

	@observable agree = false;
	@observable valid = false;

	constructor(props) {
		super(props);
		store.ui.setBreadcrumbs([{ name: 'Запись на личный прием', link: '/appointments' }]);
		this.init();
	}

	componentWillUnmount() {
		document.removeEventListener('mousemove', this.onMouseMove);
	}

	onBtnMount = el => {
		this.btn = el;
	};

	onMouseMove = e => {
		if (this.btn && this.agree) {
			const x0 = e.clientX;
			const y0 = e.clientY;
			const rect = this.btn.getBoundingClientRect();
			const x1 = rect.x;
			const y1 = rect.y;
			const x2 = x1 + rect.width;
			const y2 = y1 + rect.height;
			const dist = 50;
			if (x0 > x1 - dist && x0 < x2 + dist && y0 > y1 - dist && y0 < y2 + dist) {
				// console.log('-', x0, y0, x1, y1);
				this.checkSubject();
				this.checkLastName();
				this.checkFirstName();
				this.checkMiddleName();
				if (!this.emailError) this.checkEmail();
				this.checkAddress();
				this.checkDeputy();
				this.checkDate();
				if (this.register) {
					this.checkPassword();
					this.checkPassword2();
				}
			} else {
				this.resetErrors();
			}
		}
	};

	@action init = async () => {
		if (store.model.isAuthorized) {
			const user = await store.model.User.findById(store.model.user.id);
			this.lastName = user.lastName || '';
			this.firstName = user.firstName || '';
			this.middleName = user.middleName || '';
			this.email = user.email;
		}
		this.deputies = await getCurrentDeputies();

		const urlParams = new URLSearchParams(window.location.search);
		const deputyId = urlParams.get('deputy');
		this.deputy = deputyId ? this.deputies.find(dc => dc.id === parseInt(deputyId)) : null;
		if (this.deputy && this.deputy.appointmentDates && this.deputy.appointmentDates.length > 0) {
			this.dates = this.deputy.appointmentDates;
		}

		document.addEventListener('mousemove', this.onMouseMove);
		this.initialized = true;
	};

	@action resetErrors = () => {
		this.subjectError = '';
		this.lastNameError = '';
		this.firstNameError = '';
		this.middleNameError = '';
		if (!this.register) this.emailError = '';
		this.phoneError = '';
		this.addressError = '';
		this.deputyError = null;
		this.connectError = '';
		this.passwordError = '';
		this.password2Error = '';
		this.dateError = '';
	};

	onSubjectChange = e => (this.subject = e.target.value);
	onLastNameChange = e => (this.lastName = e.target.value);
	onFirstNameChange = e => (this.firstName = e.target.value);
	onMiddleNameChange = e => (this.middleName = e.target.value);
	onEmailChange = e => {
		this.email = e.target.value;
		this.emailError = '';
	};
	onPhoneChange = e => (this.phone = e.target.value);
	onAddressChange = e => (this.address = e.target.value);

	@action onDeputyChange = deputy => {
		this.deputy = deputy;
		this.dates = [];
		this.date = null;
		if (deputy && deputy.appointmentDates && deputy.appointmentDates.length > 0) {
			this.dates = deputy.appointmentDates;
		}
	};

	onPasswordChange = e => (this.password = e.target.value);
	onPassword2Change = e => (this.password2 = e.target.value);
	onDateChange = date => (this.date = date);

	deputyName = deputy => `${deputy.lastName || ''} ${deputy.firstName || ''} ${deputy.middleName || ''}`;

	@action checkSubject = () => {
		this.subject = this.subject ? this.subject.trim() : '';
		const valid = this.subject.length > 0;
		if (!valid) this.subjectError = 'Введите тему вопроса';
		return valid;
	};

	@action checkLastName = () => {
		this.lastName = this.lastName ? this.lastName.trim() : '';
		const valid = this.lastName.length > 0;
		if (!valid) this.lastNameError = 'Введите фамилию';
		return valid;
	};

	@action checkFirstName = () => {
		this.firstName = this.firstName ? this.firstName.trim() : '';
		const valid = this.firstName.length > 0;
		if (!valid) this.firstNameError = 'Введите имя';
		return valid;
	};

	@action checkMiddleName = () => {
		this.middleName = this.middleName ? this.middleName.trim() : '';
		const valid = this.middleName.length > 0;
		if (!valid) this.middleNameError = 'Введите отчество';
		return valid;
	};

	@action checkEmail = () => {
		this.email = this.email.trim();
		const { valid, message } = validateEmail(this.email);
		this.emailError = message || '';
		return valid;
	};

	@action checkAddress = () => {
		this.address = this.address ? this.address.trim() : '';
		const valid = this.address.length > 0;
		if (!valid) this.addressError = 'Введите адрес';
		return valid;
	};

	@action checkPassword = () => {
		const valid = this.password.length > 0;
		if (!valid) this.passwordError = 'Введите пароль';
		return valid;
	};

	@action checkPassword2 = () => {
		if (this.password2.length === 0) {
			this.password2Error = 'Повторите пароль';
			return false;
		}
		if (this.password !== this.password2) {
			this.password2Error = 'Пароли не совпадают';
			return false;
		}
		return true;
	};

	checkDeputy = () => {
		const valid = !!this.deputy;
		if (!valid) this.deputyError = 'Выберите адресата';
		return valid;
	};

	checkDate = () => {
		const valid = !!this.date;
		if (!valid) this.dateError = 'Выберите дату приема';
		return valid;
	};

	toggleRegister = e => (this.register = e.target.checked);

	@action onSubmit = async e => {
		e.preventDefault();
		this.resetErrors();
		let errors = 0;

		if (!this.valid) return;

		if (!this.checkSubject()) errors++;
		if (!this.checkLastName()) errors++;
		if (!this.checkFirstName()) errors++;
		if (!this.checkMiddleName()) errors++;
		if (!this.checkEmail()) errors++;
		if (!this.checkAddress()) errors++;
		if (!this.checkDeputy()) errors++;
		if (!this.checkDate()) errors++;
		if (!this.agree) {
			this.agreeError = 'Вы должны дать согласие';
			errors++;
		}

		if (this.register) {
			if (!this.checkPassword()) errors++;
			if (!this.checkPassword2()) errors++;
			const users = await store.model.User.find({
				where: {
					email: this.email,
				},
			});
			if (users.length > 0) {
				this.emailError = 'Такой E-mail уже зарегистрирован';
				errors++;
			}
		}

		if (errors === 0) {
			this.isLoading = true;

			const appointment = new store.model.Appointment();
			appointment.subject = this.subject;
			appointment.lastName = this.lastName;
			appointment.firstName = this.firstName;
			appointment.middleName = this.middleName;
			appointment.email = this.email;
			appointment.phone = this.phone;
			appointment.address = this.address;
			appointment.deputy = this.deputy;
			appointment.statusId = 1;
			appointment.date = this.date;

			try {
				if (this.register) {
					await store.model.register({
						firstName: this.firstName,
						middleName: this.middleName,
						lastName: this.lastName,
						email: this.email,
						password: this.password,
					});
					await store.model.login(this.email, this.password);
				}

				await appointment.save();
				if (this.file) {
					await appointment.uploadFile('attachment', this.file);
					appointment.attachment = this.file.name;
					await appointment.save();
				}
				this.appointmentId = appointment.id;

				this.props.history.push({
					pathname: `/appointments/${appointment.id}`,
					state: { isNewUser: this.register },
				});
			} catch (e) {
				this.connectError = e.message;
			}

			this.isLoading = false;
		}
	};

	showAgreement = e => {
		e.preventDefault();
		this.agreement = true;
	};

	closeAgreement = () => {
		this.agreement = false;
	};

	onAgreeChange = e => {
		this.agree = e.target.checked;
		if (this.agree) this.agreeError = '';
	};

	onCaptchaChange = res => (this.valid = res.success);
	onCaptchaExpired = () => (this.valid = false);

	render() {
		if (!this.initialized) {
			return (
				<div className="container appointments">
					<h1>Запись на личный прием</h1>
				</div>
			);
		}

		const isUser = store.model.isAuthorized;

		const deputies = this.deputies.filter(deputy => !!deputy.appointment).map(d => ({ label: this.deputyName(d), value: d }));

		return (
			<div className={'container appointments' + (this.isLoading ? ' is-loading' : '')}>
				<h1>Запись на личный прием</h1>

				<form onSubmit={this.onSubmit}>
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-6 col-12">
							<div className="form-field">
								{this.deputies.length > 0 && (
									<Select
										items={deputies}
										value={this.deputy}
										showValue={this.deputy ? this.deputyName(this.deputy) : <span>Адресат *</span>}
										onChange={this.onDeputyChange}
									/>
								)}
								<div className="error">{this.deputyError}</div>
							</div>
							<div className="form-field">
								{this.deputies.length > 0 && (
									<Select
										value={this.date}
										items={this.dates
											.filter(dateStr => parse(dateStr, dateFormat, new Date()) > Date.now())
											.map(dateStr => ({
												value: parse(dateStr, dateFormat, new Date()),
												label: dateStr,
											}))}
										noSearch={true}
										isRequired={true}
										disabled={this.dates.length === 0}
										emptyContent={<div className="empty-content">Нет доступных дат</div>}
										showValue={this.date ? format(this.date, dateFormat) : <span>Дата приема *</span>}
										onChange={this.onDateChange}
									/>
								)}
								<div className="error">{this.dateError}</div>
							</div>
							<div className="form-field">
								<input
									type="text"
									value={this.lastName}
									onChange={this.onLastNameChange}
									placeholder="Фамилия *"
									disabled={isUser && store.model.user.lastName}
								/>
								<div className="error">{this.lastNameError}</div>
							</div>
							<div className="form-field">
								<input
									type="text"
									value={this.firstName}
									onChange={this.onFirstNameChange}
									placeholder="Имя *"
									disabled={isUser && store.model.user.firstName}
								/>
								<div className="error">{this.firstNameError}</div>
							</div>
							<div className="form-field">
								<input
									type="text"
									value={this.middleName}
									onChange={this.onMiddleNameChange}
									placeholder="Отчество *"
									disabled={isUser && store.model.user.middleName}
								/>
								<div className="error">{this.middleNameError}</div>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-12">
							<div className="form-field">
								<input type="text" value={this.subject} onChange={this.onSubjectChange} placeholder="Тема вопроса *" />
								<div className="error">{this.subjectError}</div>
							</div>
							<div className="form-field">
								<input type="text" value={this.email} onChange={this.onEmailChange} placeholder="E-mail *" disabled={isUser} />
								<div className="error">{this.emailError}</div>
							</div>
							<div className="form-field">
								<input type="text" value={this.address} onChange={this.onAddressChange} placeholder="Адрес места жительства *" />
								<div className="error">{this.addressError}</div>
							</div>
							<div className="form-field">
								<MaskedInput
									mask="+7 999 999 9999"
									value={this.phone}
									onChange={this.onPhoneChange}
									placeholder="Контактный телефон"
								/>
								{/*<input type="text" value={this.phone} onChange={this.onPhoneChange} placeholder="Контактный телефон" />*/}
								<div className="error">{this.phoneError}</div>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-12">
							{!isUser && (
								<div className="form-field register">
									<input type="checkbox" checked={this.register} onChange={this.toggleRegister} /> Создать личный кабинет
								</div>
							)}
							{this.register && (
								<>
									<div className="form-field">
										<input
											type="password"
											value={this.password}
											autoComplete="new-password"
											onChange={this.onPasswordChange}
											placeholder="Пароль *"
										/>
										<div className="error">{this.passwordError}</div>
									</div>
									<div className="form-field">
										<input
											type="password"
											value={this.password2}
											autoComplete="new-password"
											onChange={this.onPassword2Change}
											placeholder="Повторите пароль *"
										/>
										<div className="error">{this.password2Error}</div>
									</div>
								</>
							)}
							<span className="form-field agreement">
								<input type="checkbox" value={this.agree} onChange={this.onAgreeChange} /> {t('agreeText')}
								<div className="error">{this.agreeError}</div>
							</span>

							<ReCaptcha onChange={this.onCaptchaChange} onExpired={this.onCaptchaExpired} />

							<div className="submit">
								<button className="red-btn" type="submit" ref={this.onBtnMount} disabled={!this.valid}>
									Отправить
								</button>
								{this.isLoading && <Loader />}
								<div className="error">{this.connectError}</div>
							</div>
						</div>
					</div>
				</form>

				{this.agreement && (
					<Popup portalClassName="agreement-popup" width={800} height={500} onClose={this.closeAgreement}>
						<h2>Agreement</h2>
						<div style={{ height: 600 }} />
					</Popup>
				)}
			</div>
		);
	}
}
