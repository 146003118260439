import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { groups, plural, LAW, DECREE, LAW_PASSPORT, DECREE_PASSPORT, LAW_Y } from './groups';
import store from '../../store';
import { downloadFile, formatDate, trimmedName } from './tools';
import './style.scss';
const YAK_LANG_ID = 10218749;
@observer
export default class Passport extends React.Component {
	@observable isLoading = true;
	@observable record = null;
	@observable docWho = [];
	@observable who = [];
	@observable error = null;
	@observable steps = [];

	constructor(props) {
		super(props);
		this.record = props.record;
		this.init();
	}

	@action init = async () => {
		try {
			const yakLanguage = await store.model.DocFileType.findOne({ where: { externalId: YAK_LANG_ID } });
			this.yakLanguageId = yakLanguage.id;
		} catch (e) {
			console.log(e);
		}
		this.steps = await store.model.RefLink.find({
			where: { documentId: this.record.id, childId: { neq: null } },
			order: 'orderNum asc',
			include: [
				{
					relation: 'child',
					scope: {
						include: [
							/*
							{
								relation: 'refCorresp',
								scope: {
									include: [
										{ relation: 'organization' }
									],
								}
							},
*/
							{
								relation: 'refFiles',
								scope: {
									include: ['docFile'],
								},
							},
							{
								relation: 'refLinks',
								scope: {
									include: [
										{
											relation: 'child',
											scope: {
												include: [
													{
														relation: 'refFiles',
														scope: {
															include: ['docFile'],
														},
													},
												],
											},
										},
									],
								},
							},
						],
					},
				},
			],
		});

		this.steps.forEach(step => console.log('-', step.orderNum, step.name));
		this.isLoading = false;
	};

	cutExtension = filename =>
		filename && filename.search(/\./) !== -1
			? filename
					.split('.')
					.slice(0, -1)
					.join('.')
			: filename || '';

	docFiles = (document, onlyYakLanguage = false) => {
		if (!document) {
			return 'No files!';
		}
		let files = document.refFiles().filter(refFile => {
			if (onlyYakLanguage) return refFile.docFile.typeId === this.yakLanguageId;
			else return refFile.docFile.typeId !== this.yakLanguageId;
		});

		files = files.map(refFile => {
			const docFile = refFile.docFile;
			if (!docFile)
				return (
					<div className="doc-file" key={refFile.id}>
						No file! (refFile.id: {refFile.id}, {refFile.docFileId || 'undefined'})
					</div>
				);
			const url = docFile.downloadFile('contents');
			return (
				<div className="doc-file" key={refFile.id}>
					<a target="_blank" href={url}>
						{this.cutExtension(docFile.contents || refFile.description)}
					</a>
				</div>
			);
		});
		return files;
	};

	refFileLink = refFile => {
		const docFile = refFile.docFile;
		return docFile ? docFile.downloadFile('contents') : null;
	};

	refLinkFiles = refLink => <ul className="doc-files">{this.docFiles(refLink.child)}</ul>;

	getReadingNum = str => {
		if (/1, 2, 3 чтени/.test(str)) return 'в 1, 2, 3 чтениях';
		if (/1 чтени/.test(str)) return 'в 1 чтении';
		if (/2 чтени/.test(str)) return 'во 2 чтении';
		if (/2, 3 чтени/.test(str)) return 'во 2 чтении';
		if (/3 чтени/.test(str)) return 'в 3 чтении';
		return null;
	};

	render() {
		if (this.isLoading) return '...';
		if (this.error) return this.error;

		let typePath = 'laws';
		if (this.record.docGroup === DECREE) {
			typePath = 'decrees';
		} else if (this.record.docGroup === LAW_PASSPORT) {
			typePath = 'law-projects';
		} else if (this.record.docGroup === DECREE_PASSPORT) {
			typePath = 'decree-projects';
		}

		const initiative = this.steps.find(refLink => new RegExp('Законодательная инициатива', 'i').test(refLink.name));

		const initiativeResolution = initiative
			? initiative.child.refLinks().find(refLink => new RegExp('Заключения государственно - правового управления', 'i').test(refLink.name))
			: null;

		const laws = [];
		const readings = [];
		this.steps.forEach((refLink, i) => {
			if (/чтени(е|я)/.test(refLink.name)) {
				const reading = refLink;

				reading.num = this.getReadingNum(reading.name);
				console.group('> reading', reading.name);

				reading.committeeResolution = reading.child
					.refLinks()
					.find(refLink => /Решение/i.test(refLink.name) && /комитет/i.test(refLink.name));
				console.log('committeeResolution', reading.committeeResolution ? reading.committeeResolution.name : null);

				reading.committeeMeeting = reading.child.refLinks().find(refLink => /Заседание/i.test(refLink.name) && /комитет/i.test(refLink.name));
				console.log(
					'committeeMeeting',
					reading.committeeMeeting ? `${reading.committeeMeeting.child.freeNum} ${reading.committeeMeeting.child.name}` : null
				);

				reading.plenary = reading.child.refLinks().find(refLink => /Пленарное/i.test(refLink.name) && /заседание/i.test(refLink.name));
				console.log('plenary', reading.plenary ? reading.plenary.child.name : null);

				for (let j = i + 1; j < this.steps.length; j++) {
					const step = this.steps[j];
					if (/чтени(е|я)/.test(step.name)) break;
					// console.log('>> check');
					if (/Постановление Государственного Собрания/.test(step.name)) {
						reading.decree = step;
					}
					if (/Закон Республики/.test(step.name)) {
						reading.law = step;
					}
				}

				if (reading.decree) console.log('decree', reading.decree.orderNum, reading.decree.name);
				if (reading.law) console.log('law', reading.law.orderNum, reading.law.name);
				console.groupEnd();

				readings.push(reading);
			}
			if (/Закон Республики/.test(refLink.name)) {
				laws.push(refLink);
			}
		});

		const renderTree = (yakLang = false) => {
			let law = laws.find(law => law.child.docGroup === LAW);
			if (yakLang) {
				// если нет закона на якутском,то рендерим ссылку на русский
				law = laws.find(law => law.child.docGroup === LAW_Y) || law;
			}
			return (
				<div>
					{initiative && (
						<div className="step">
							<div className="step-name">
								<div className="step-line" />
								<div className="step-point" />
								<strong>Внесение законопроекта в Государственное Собрание</strong>
							</div>
							{initiative && (
								<div className="substep">
									<div className="date">
										{initiative.child ? formatDate(initiative.child.date) : ''}
										<div className="substep-point" />
										<div className="substep-line" />
									</div>
									<div className="content">
										<strong>Регистрация законопроекта и материалов к нему</strong>
										{this.docFiles(initiative.child, yakLang)}
									</div>
								</div>
							)}
							{initiativeResolution && (
								<div className="substep">
									<div className="date">
										{initiativeResolution.child ? formatDate(initiativeResolution.child.date) : ''}
										<div className="substep-point" />
										<div className="substep-line" />
									</div>
									<div className="content">
										<strong>Заключение государственно-правового управления</strong>
										{this.docFiles(initiativeResolution.child, yakLang)}
									</div>
								</div>
							)}
						</div>
					)}

					{readings.map((reading, i) => (
						<div key={i} className="step">
							<div className="step-name">
								<div className="step-line" />
								<div className="step-point" />
								<strong>Рассмотрение законопроекта {reading.num}</strong>
							</div>
							{reading.committeeResolution && reading.committeeMeeting && (
								<div className="substep">
									<div className="date">
										{reading.committeeResolution.child ? formatDate(reading.committeeResolution.child.date) : ''}
										<div className="substep-point" />
										<div className="substep-line" />
									</div>
									<div className="content">
										<strong>Рассмотрение законопроекта ответственным комитетом</strong>
										{reading.committeeMeeting.child.name} № {reading.committeeMeeting.child.freeNum}
										{this.docFiles(reading.committeeResolution.child, yakLang)}
									</div>
								</div>
							)}
							{!reading.committeeResolution && reading.committeeMeeting && (
								<div className="substep">
									<div className="date">
										<div className="substep-point" />
										<div className="substep-line" />
									</div>
									<div className="content">
										<strong>Рассмотрение законопроекта ответственным комитетом</strong>
										{reading.committeeMeeting.child.name} № {reading.committeeMeeting.child.freeNum}
									</div>
								</div>
							)}
							{reading.plenary && (
								<div className="substep">
									<div className="date">
										{reading.plenary.child ? formatDate(reading.plenary.child.date) : ''}
										<div className="substep-point" />
										<div className="substep-line" />
									</div>
									<div className="content">
										<strong>Рассмотрение законопроекта на пленарном заседании</strong>
										{reading.plenary.child.name} № {reading.committeeMeeting.child.freeNum}
										{reading.decree && (
											<div className="doc-file">
												{reading.decree.child &&
													reading.decree.child.refFiles().map(refFile => (
														<a key={refFile.id} target="_blank" href={this.refFileLink(refFile)}>
															Постановление Государственного Собрания (Ил Тумэн) Республики Саха (Якутия){' '}
															{reading.decree.child.freeNum}
														</a>
													))}
											</div>
										)}
										{reading.law && reading.law.child && reading.law.child.refFiles().length > 0 && (
											<div className="doc-file">
												<a key="law" target="_blank" href={this.refFileLink(reading.law.child.refFiles()[0])}>
													Закон Республики Саха (Якутия) {reading.law.child.freeNum}
												</a>
											</div>
										)}
									</div>
								</div>
							)}
						</div>
					))}

					{law && (
						<div className="step">
							<div className="step-name">
								<div className="step-line" />
								<div className="step-point" />
								<strong>Опубликование</strong>
							</div>
							<div className="substep">
								<div className="date">
									<div className="substep-point" />
									<div className="substep-line" />
								</div>
								<div className="content">
									<strong>Официальный сайт Государственного Собрания (Ил Тумэн)</strong>
									<div className="doc-file">
										<Link to={`/documents/${law.child.id}`}>
											Закон Республики Саха (Якутия) от {formatDate(law.child.date)} № {law.child.freeNum} «{law.child.name}
										</Link>
									</div>
								</div>
							</div>
						</div>
					)}
					{this.record.ZPResult && this.record.ZPResult !== 'null' && (
						<div className="step">
							<div className="step-name">
								<div className="step-line last" />
								<div className="step-point" />
								<strong>Результат по ЗП</strong>
							</div>
							{this.record.ZPResult}
						</div>
					)}
				</div>
			);
		};

		const hasYakFiles = !!this.record.refFiles().find(refFile => refFile.docFile && refFile.docFile.typeId === this.yakLanguageId);
		const hasYakLaw = !!laws.find(law => law.child.docGroup === LAW_Y);

		return (
			<>
				<div className="doc-name">
					<strong>{trimmedName(this.record.name)}</strong>
				</div>
				<div className="passport">
					{renderTree()}
					{(hasYakFiles || hasYakLaw) && renderTree(true)}
				</div>
			</>
		);
	}
}
