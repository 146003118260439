import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Tab, Tabs } from '../../components/route-tabs';
import store from '../../store';
import Convocation from './Convocation';
import './style.scss';
import { Button } from 'root/site/pages/sessions/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { formatDateStr } from './../../utils/dateUtils';
import { Loader } from '@smartplatform/ui';

@observer
export default class List extends React.Component {
	@observable isLoading = true;
	@observable convocations = [];
	@observable isDownloading = false;

	constructor(props) {
		super(props);
		store.ui.setBreadcrumbs([{ name: 'Депутаты', link: '/deputies' }]);
		this.init();
		this.ref = React.createRef();
	}

	@action init = async () => {
		const where = {};
		const [settingShowOldConvocations] = await store.model.Config.find({ where: { code: 'showOldConvocations' }, limit: 1 });
		const showOldConvocations = settingShowOldConvocations && [true, 'true'].includes(settingShowOldConvocations.value);
		if (!store.model.user && showOldConvocations) where.current = true;
		this.convocations = await store.model.Convocation.find({ where: where, order: 'id desc' });
		this.isLoading = false;
	};

	downloadPdf = async () => {
		// если данные уже есть в рефе, то запрос не нужен, т.к данные не меняются
		if (!this.ref.current.href) {
			try {
				this.isDownloading = true;
				const pdfBuffer = await store.model.Deputy.htmlToPDF(this.convocations[0].id);
				const downloadLink = this.ref.current;
				const currentDate = formatDateStr(new Date(), 'dd.MM.yyyy');
				downloadLink.href = 'data:application/pdf;base64,' + pdfBuffer.$data.toString();
				downloadLink.download = `Cписок действующих депутатов на ${currentDate}.pdf`;
				downloadLink.click();
			} catch (e) {
				console.log('Download pdf error: ', e);
			} finally {
				this.isDownloading = false;
			}
		}
	};

	render() {
		if (this.isLoading) return '...';

		return (
			<div className="container deputies-list">
				{/*<a ref={this.ref}>
					<Button className="deputies-list-pdf" disabled={this.isDownloading} onClick={this.downloadPdf}>
						<FontAwesomeIcon icon={faFilePdf} /> Скачать актуальный список действующих депутатов{' '}
						{this.isDownloading && <Loader size={14} />}
					</Button>
				</a>*/}
				<Tabs>
					{this.convocations.map((conv, i) => {
						return i === 0 ? (
							<Tab key={conv.id} title={conv.name} path="/deputies" exact>
								<Convocation id={conv.id} />
							</Tab>
						) : (
							<Tab key={conv.id} title={conv.name} path={`/deputies/conv/${conv.id}`}>
								<Convocation id={conv.id} />
							</Tab>
						);
					})}
				</Tabs>
			</div>
		);
	}
}
